import { Image, ViewSeo } from "@ctv/shared-core/src";
import TrackingLocalizedLink from "@ctv/shared/components/Tracking/TrackingLocalizedLink";
import {
  HeaderTrackingPage,
  trackTvlkImageClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";

import HamburgerMenu from "./HamburgerMenu";
import LanguageMenu from "./LanguageMenu";

import styles from "./Header.module.css";

type Props = {
  track?: HeaderTrackingPage;
};

export default function Header(props: Props) {
  const { track } = props;

  return (
    <ViewSeo
      className={styles.container}
      row
      align="center"
      justify="between"
      spacing="m"
    >
      <TrackingLocalizedLink href="/" track={trackTvlkImageClick(track)}>
        <Image
          src="/images/traveloka-for-corporates.png"
          alt="logo"
          width={212}
        />
      </TrackingLocalizedLink>
      <LanguageMenu />
      <HamburgerMenu track={track} />
    </ViewSeo>
  );
}
