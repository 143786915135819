import { useTracker } from "@ctv/core";
import { LocalizedLink } from "@ctv/shared-core/src";
import { LocalizedLinkProps } from "@ctv/shared-core/src/core/locale/components/LocalizedLink";
import { TrackingType } from "@ctv/shared/tracking/types";

type Props = LocalizedLinkProps & {
  track?: TrackingType;
};

export default function TrackingLocalizedLink(props: Props) {
  const { track, onClick, ...rest } = props;

  const doTracking = useTracker();

  return (
    <LocalizedLink
      {...rest}
      onClick={(e) => {
        if (track) {
          doTracking(track.event, track.data);
        }
        onClick?.(e);
      }}
    />
  );
}
